html,
body,
.app {
  height: 98vh;
  background: #eee;
  padding: 1em;
}

/* SidePanel */
.menu {
  padding-bottom: 1em;
}
.menu span {
  font-weight: 600;
}

/* Middle column */
.mid__col {
  display: flex !important;
  flex-direction: column;
}

/* Messages */
.messages__row {
  align-self: stretch;
  flex: auto;
}

.messages {
  height: 58vh;
  overflow: auto;
}
.ui.comments {
  width: 100%;
  max-width: 100%;
}

.messages__progress {
  /* height: 58vh; */
  overflow: scroll;
}

.css-1u6iuxw {
  height: 523px;
  width: auto;
}

/* message form */
.message__form {
  /* position: fixed !important; */
  /* bottom: 1em; */
  /* margin-left: 370px !important; 
  left: 0;
  right: 1em;
  z-index: 200;*/
  align-self: end;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.emojipicker {
  position: absolute;
}

/* Message */
.message__self {
  border-left: 2px solid orange;
  padding-left: 8px;
}

.message__image {
  padding: 1em;
}

/* Progres bar */
.progres__bar {
  margin: 0;
  margin-top: 0.3em !important;
}

/* color panel */
.color__container {
  position: relative;
  overflow: hidden;
  width: 35px;
  border-radius: 3px;
  cursor: pointer;
}
.color__square {
  width: 35px;
  height: 35px;
}
.color__overlay {
  width: 85px;
  height: 35px;
  transform: rotate(225deg);
}

/* Typing */

.user__typing {
  font-style: italic;
  font-weight: bold;
  margin-right: 3px;
}

.typing {
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d89c1;
  border-radius: 50%;
  opacity: 0;
  animation-name: loadingFade;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

/* Skeleton */
.skeleton {
  position: relative;
  overflow: hidden;
  height: 40px;
  margin-bottom: 10px;
}
.skeleton::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  animation: sweep 2s infinite;
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
}

@keyframes sweep {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.skeleton__avatar {
  height: 35px;
  width: 35px;
  border-radius: 3px;
  background-color: rgba(50, 57, 57, 0.3);
}

.skeleton__author {
  height: 10px;
  width: 120px;
  border-radius: 3px;
  background-color: rgba(50, 57, 57, 0.3);
  position: absolute;
  bottom: 30px;
  left: 40px;
  right: 0;
}

.skeleton__details {
  height: 20px;
  border-radius: 3px;
  background-color: rgba(50, 57, 57, 0.3);
  position: absolute;
  bottom: 5px;
  left: 40px;
  right: 20px;
}

.org-logo {
  width: 200px;
}

.circle {
  border-radius: 100% !important;
}
.admin-sidebar {
  background-color: #051e34 !important;
  border-right: solid 3px #527798 !important;
}
.admin-sidebar > .ui.grid > .menu > .item {
  font-size: 1.1rem;
}
.admin-sidebar .menu .active {
  color: #669df6 !important;
}

.admin-profile {
  background-color: #011527;
  border-bottom: solid 1px #ffffff;
}

.channels-panel {
  padding-left: 40px !important;
}

.channels-panel-item {
  cursor: pointer;
}

.channels-panel-item:hover {
  transform: scale(1.05);
}

.setting-notification label {
  color: white !important;
}

.setting-notification .title-label {
  position: absolute !important;
  margin-left: 20px !important;
  margin-top: -2px !important;
}
.display-flex {
  display: flex;
}

.my__organization {
  cursor: pointer;
}

.border-none {
  border: none !important;
}

.float-right {
  float: right !important;
}
.right__panel__user__name {
  vertical-align: -webkit-baseline-middle !important;
}
.pt-6 {
  padding-top: 6px !important;
}

.place-self-center {
  place-self: center !important;
}

.w-100 {
  width: 100% !important;
}

.user__info {
  padding: 14px;
}
.user__info p {
  margin-bottom: 0 !important;
}
.user__info .mail {
  min-inline-size: max-content;
}
.user__info ul {
  margin: 0 !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pt-5 {
  padding-top: 5px !important;
}

.search__table {
  position: relative !important;
  width: 100% !important;
}
.search__input {
  width: 100% !important;
}

.search__input > div {
  width: 100% !important;
}
.search__input > div > input {
  width: 100% !important;
}
.user_list {
  height: 174px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.pr-p5 {
  padding-right: 0.5em !important;
}

#users_list::-webkit-scrollbar {
  position: fixed;
  display: none;
}

#users_list:hover#users_list::-webkit-scrollbar {
  display: block;
}

/* Hide scrollbar for IE, Edge and Firefox */
#users_list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#users_list:hover {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

.addmember_to_area {
  background: white !important;
  color: black !important;
}

.addmember_to_area * {
  color: black !important;
}
.addmember_to_area-userlist {
  height: 50vh;
  overflow-y: auto;
}
.addmember_to_area-userlist-action {
  float: right !important;
}

/* .addmember_to_area .search_table{

} */
.addmember_to_area .search_table td {
  padding: 0 0 0 0 !important;
  font-size: 1rem !important;
}
.addmember_to_area .search_table td.pl-5 {
  padding-left: 10px !important;
}

.search_table .search__input .search-name {
  font-size: 1.1rem;
}

.bg-c-lightblue {
  background-color: lightblue !important;
}

.custom_chanel_divider {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-left: 20px;
  background: red;
  color: black;
}

.cutom_areas_title {
  position: absolute;
  right: 1.33333333em !important;
}

.pr-1 {
  padding-right: 1em;
}

.pointer {
  cursor: pointer;
}

.show {
  display: inline;
  transition: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: 2s;
}

.hide {
  display: none;
  transition: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: 2s;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.none-style {
  border: none;
}

.none-style.input > input {
  border: none !important;
}
.none-style.disabled.input {
  opacity: 1 !important;
}

.ui.button.fileName {
  padding: 1px 5px 1px 5px !important;
}
